import { Component, TemplateRef, inject } from '@angular/core'
import { ToastService } from 'src/app/services/toast.service'
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap'
import { SafeHtmlPipe } from '../../pipes/safehtml.pipe'

@Component({
    selector: 'app-toasts',
    imports: [NgbToastModule, SafeHtmlPipe],
    templateUrl: './toasts-container.component.html',
    host: { class: 'toast-container position-fixed end-0 p-3', style: 'top: 100px; z-index: 1200' }
})
export class ToastsContainer {
	toastService = inject(ToastService)


	isTemplate(toast: { textOrTpl: any; }) {
		return toast.textOrTpl instanceof TemplateRef
	}
}