import { Component, OnInit, inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { IconNamesEnum } from 'ngx-bootstrap-icons'
import { BackEndService } from 'src/app/services/backend.service'
import { FormsModule } from '@angular/forms'
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap'
import { PublicPageHeaderComponent } from '../../shared/components/public-page-header/public-page-header.component'

@Component({
    selector: 'app-unsubscribe-page',
    templateUrl: './unsubscribe-page.component.html',
    styleUrls: ['./unsubscribe-page.component.scss'],
    imports: [PublicPageHeaderComponent, NgbProgressbar, FormsModule]
})
export class UnsubscribePageComponent implements OnInit {
  store = inject<Store<any>>(Store)
  private router = inject(Router)
  private backendService = inject(BackEndService)

  public iconNames = IconNamesEnum
  private hash: string

  public area : string = ''
  public articleID : number = 0
  public loading = true
  public isWaiting = true
  public pageMode = "unsubscribe"
  public message = "unsubscribe"
  public title = "Newsletter"


  constructor() {
    const route = inject(ActivatedRoute)

    this.hash = route.snapshot.queryParamMap.get('u') ?? ''
  }

  ngOnInit(): void {
    this.backendService.getUnsubscribeInformation(this.hash).subscribe((response: any) => {
      this.loading = false
      if (response.result.loginOk) {
        if (response.result.info) {
          this.title = response.result.info.title
        } else {
          this.pageMode = "messaging"
          this.message = "There is a problem with that link or the item you are trying to unsubscribe from is not yours."
        }
      }
    })
  }

  onSubmit() {
    this.isWaiting = true
    this.backendService.unsubscribe(this.hash).subscribe((response: any) => {
      this.isWaiting = false
      this.pageMode = 'messaging'
      this.message = response.result.message
    })
  }
}
