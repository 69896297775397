import { Component, OnInit, inject } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { take } from 'rxjs'
import { selectUser } from 'src/app/state/user.reducer'

@Component({
    selector: 'app-goto-saved-search',
    imports: [],
    templateUrl: './goto-saved-search.component.html',
    styleUrls: ['./goto-saved-search.component.scss']
})
export class GotoSavedSearchComponent implements OnInit {
  private store = inject<Store<any>>(Store)
  private route = inject(ActivatedRoute)
  private router = inject(Router)


  ngOnInit(): void {
    this.store.select(selectUser).pipe(take(1)).subscribe(user => {
      const alertID = this.route.snapshot.paramMap.get('alertid') ?? ''

      if (user !== undefined && alertID !== '') {
        for (let [area, areaSavedSearch] of Object.entries(user!.searches)) {
          for (const search of areaSavedSearch) {
            if (search.id === alertID) {
              if (area === 'data') {
                area = search.parameters.area ?? area
              }

              let searchParameters = structuredClone(search.parameters)

              for (let [key, options] of Object.entries(searchParameters.parameters) as any) {
                if (options.type === 'daterange') {
                  options.value[1] = new Date()

                  let date = new Date()
                  const dateRange = options.value[2] ?? null
                  if (dateRange) {
                    switch (dateRange) {
                      case '1d':
                        date.setDate(date.getDate() - 1)
                        options.value[0] = date
                        break
                      case 'wd':
                        let day
                        do {
                          date.setDate(date.getDate() - 1)
                          day = date.getDay()
                        } while (day === 0 || day === 6)
                        options.value[0] = date
                        options.value[1] = date
                        break
                      case '1w':
                        date.setDate(date.getDate() - 7)
                        options.value[0] = date
                        break
                      case '1m':
                        date.setMonth(date.getMonth() - 1)
                        options.value[0] = date
                        break
                      case '1y':
                        date.setFullYear(date.getFullYear() - 1)
                        options.value[0] = date
                        break
                    }
                  }
                  searchParameters.parameters[key] = options
                }
              }
              this.router.navigateByUrl('/' + area, {
                state: searchParameters
              })
              return
            }
          }
        }
      }
    })
  }
}