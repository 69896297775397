/// <reference types="@angular/localize" />

import * as Sentry from "@sentry/angular"
import { isDevMode, ErrorHandler, importProvidersFrom, inject, PLATFORM_ID, provideAppInitializer } from '@angular/core'
import { AppComponent } from './app/app.component'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { DynamicModule } from 'ng-dynamic-component'
import { UserEffects } from './app/state/user.effects'
import { ArticlesEffects } from './app/state/article.effects'
import { TitleEffects } from './app/state/title.effects'
import { RouterEffects } from './app/state/router.effects'
import { EffectsModule } from '@ngrx/effects'
import { extModules } from './app/build-specifics'
import { userFeature } from './app/state/user.reducer'
import { articlesFeature } from './app/state/article.reducer'
import { menusFeature } from './app/state/menus.reducer'
import { StoreModule } from '@ngrx/store'
import { LibMasterDetailModule } from '@lib/master-detail'
import { provideAnimations } from '@angular/platform-browser/animations'
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http'
import { NgxBootstrapIconsModule, alarm, alarmFill, arrowClockwise, arrowsAngleExpand, asterisk, at, bodyText, bookmark, borderStyle, boxArrowUpRight, briefcase, browserChrome, building, buildings, buildingCheck, calendar4Range, caretDown, caretDownFill, caretLeft, caretRight, caretUp, caretUpFill, chatLeftDots, check2, check2All, chevronDown, chevronDoubleDown, chevronLeft, chevronRight, chevronUp, circleHalf, clipboard, collection, currencyDollar, dashCircle, download, envelopeAt, envelopePlus, envelopePlusFill, exclamationTriangle, file, fileEarmarkCheck, fileEarmarkDiff, fileEarmarkExcel, fileExcel, filePdf, house, houseFill, infoCircle, inputCursorText, linkedin, list, listCheck, lock, moonFill, moonStarsFill, pencilSquare, people, percent, person, personFill, plusCircle, printer, printerFill, save, saveFill, search, share, sortAlphaDown, sortAlphaUp, sortNumericDown, sortNumericUp, sunFill, table, tags, telephoneOutbound, trash, trashFill, trash2, trash2Fill, uiChecks, sortAlphaDownAlt, sortAlphaUpAlt, sortNumericDownAlt, sortNumericUpAlt, x, sendCheck, chatDotsFill } from 'ngx-bootstrap-icons'
import { AppRoutingModule } from './app/app-routing.module'
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser'
import { provideClarity } from 'ngx-clarity'
import { NG_SCROLLBAR_OPTIONS, NgScrollbarModule } from 'ngx-scrollbar'
import { SafeHtmlPipe } from './app/shared/pipes/safehtml.pipe'
import { StripTagsPipe } from './app/shared/pipes/striptags.pipe'
import { CookieService } from 'ngx-cookie-service'
import { DatePipe, DecimalPipe, DATE_PIPE_DEFAULT_OPTIONS, CommonModule } from '@angular/common'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'

const icons = {
    alarm,
    alarmFill,
    arrowClockwise,
    arrowsAngleExpand,
    asterisk,
    at,
    bodyText,
    bookmark,
    borderStyle,
    boxArrowUpRight,
    briefcase,
    browserChrome,
    building,
    buildings,
    buildingCheck,
    calendar4Range,
    caretDown,
    caretDownFill,
    caretLeft,
    caretRight,
    caretUp,
    caretUpFill,
    chatDotsFill,
    chatLeftDots,
    check2,
    check2All,
    chevronDown,
    chevronDoubleDown,
    chevronLeft,
    chevronRight,
    chevronUp,
    circleHalf,
    clipboard,
    collection,
    currencyDollar,
    dashCircle,
    download,
    envelopeAt,
    envelopePlus,
    envelopePlusFill,
    exclamationTriangle,
    file,
    fileEarmarkCheck,
    fileEarmarkDiff,
    fileEarmarkExcel,
    fileExcel,
    filePdf,
    house,
    houseFill,
    infoCircle,
    inputCursorText,
    linkedin,
    list,
    listCheck,
    lock,
    moonFill,
    moonStarsFill,
    pencilSquare,
    people,
    percent,
    person,
    personFill,
    plusCircle,
    printer,
    printerFill,
    save,
    saveFill,
    search,
    sendCheck,
    share,
    sortAlphaDownAlt,
    sortAlphaUpAlt,
    sortNumericDownAlt,
    sortNumericUpAlt,
    sunFill,
    table,
    tags,
    telephoneOutbound,
    trash,
    trashFill,
    trash2,
    trash2Fill,
    uiChecks,
    x,
}

if (!isDevMode()) {
    Sentry.init({
        dsn: 'https://dd6a27bb8ba04c5c8026d3d1d2de574b@app.glitchtip.com/6807',
        release: environment.commit_sha,
        tracePropagationTargets: ["localhost", /app\.bankruptcydata\.com/],
        integrations: [
            Sentry.browserTracingIntegration({

            }),
        ],
        autoSessionTracking: false,
        // Performance Monitoring
        tracesSampleRate: 0.01, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, //
        ignoreErrors: [
            'ResizeObserver loop completed with undelivered notifications.',
            'Animation.currentTime setter',
            'The provided value is non-finite',
            'NG02100',
            "getComputedStyle",
            "Failed to fetch dynamically imported module",
            "View transition",
            "Old view transition aborted by new view transition."
        ]
    })
}

// Import the echarts core module, which provides the necessary interfaces for using echarts.
// import * as echarts from 'echarts/core'
import { Router } from "@angular/router"
import { CalendarModule, DateAdapter } from "angular-calendar"
import { environment } from "./environments/environment"
import { DeviceDetectorService } from "ngx-device-detector"
// Import bar charts, all with Chart suffix
// echarts.use([TitleComponent, TooltipComponent, GridComponent, BarChart, CanvasRenderer, LineChart, LegendComponent])

const deviceService = new DeviceDetectorService('PLATFORM_ID')
deviceService.setDeviceInfo(navigator.userAgent)

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            AppRoutingModule,
            CommonModule,
            NgxBootstrapIconsModule.pick(icons, {
                width: '16px',
                height: '16px',
            }),
            // HttpClientXsrfModule,
            LibMasterDetailModule,
            StoreModule.forRoot({}),
            StoreModule.forFeature(menusFeature),
            StoreModule.forFeature(articlesFeature),
            StoreModule.forFeature(userFeature),
            extModules,
            EffectsModule.forRoot(RouterEffects, TitleEffects),
            EffectsModule.forFeature(ArticlesEffects),
            EffectsModule.forFeature(UserEffects),
            DynamicModule,
            StoreRouterConnectingModule.forRoot(),
            NgScrollbarModule,
            CalendarModule.forRoot({
                provide: DateAdapter,
                useFactory: adapterFactory,
            })
            ,
        ),
        DatePipe,
        DecimalPipe,
        CookieService,
        StripTagsPipe,
        SafeHtmlPipe,
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useValue: {
                dateFormat: 'mediumDate'
            }
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        provideAppInitializer(() => {
            inject(Sentry.TraceService)
        }),
        {
            provide: NG_SCROLLBAR_OPTIONS,
            useValue: {
                autoHeightDisabled: false,
                visibility: "visible",
                orientation: "vertical",
            }
        },
        provideClarity({
            enabled: !deviceService.getDeviceInfo().browser.includes('Safari'),
            projectId: 'jo8rv5kqjd',
        }),
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
    ]
})
    .catch(err => console.error(err))