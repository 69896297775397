import { Component, inject } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap'
import { NgScrollbarModule } from 'ngx-scrollbar'
import { AuthService } from 'src/app/services/auth.service'
import { BackEndService } from 'src/app/services/backend.service'
import { PasswordStrengthComponent } from 'src/app/shared/components/password-strength/password-strength.component'
import { PublicPageHeaderComponent } from 'src/app/shared/components/public-page-header/public-page-header.component'
import { validate as uuidValidate } from 'uuid'

@Component({
    selector: 'app-change-password',
    imports: [
        PublicPageHeaderComponent,
        NgbProgressbar,
        NgScrollbarModule,
        FormsModule,
        PasswordStrengthComponent
    ],
    templateUrl: './change-password.component.html',
    styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {

  public token: string
  public tokenValid: boolean
  public tokenOK: boolean = true
  public validatingToken: boolean = true
  public passwordChangedOK: boolean = true

  private route = inject(ActivatedRoute)
  private authService = inject(AuthService)
  private backendService = inject(BackEndService)


  public newPassword = ''
  public strongPassword = false

  public passwordChanging = false
  public askingForPassword = true

  constructor() {
    this.token = this.route.snapshot.paramMap.get('token') ?? ''
    this.tokenValid = uuidValidate(this.token)
  }

  ngOnInit(): void {
    this.authService.validateTokenCookie(this.token).subscribe((response: any) => {
      this.validatingToken = false
      if (response?.loggedIn) {

      } else {
        this.tokenOK = false
      }
    })
  }

  changePassword() {
    this.passwordChanging = true
    this.backendService.changePassword({
      currentPassword: this.token,
      newPassword: this.newPassword
    }).subscribe((result: any) => {
      this.passwordChanging = false
      this.passwordChangedOK = result.ok
      this.askingForPassword = !result.ok
    })
  }

  onPasswordStrengthChanged(event: boolean) {
    this.strongPassword = event
  }
}
