import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core'
import { NavigationEnd, Params, Router, RouterLink } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable, Subscription } from 'rxjs'
import { Article, selectCurrentArticle } from 'src/app/state'
import { isLoggedIn } from 'src/app/state/user.reducer'
import { Dictionary } from '../filtering-search-component-base/filtering-search-types'
import { AsyncPipe } from '@angular/common'
import { BreadcrumbButtonsComponent } from '../breadcrumb-buttons/breadcrumb-buttons.component'

interface Breadcrumb {
  name: string
  active: boolean
  link?: string
  queryParams?: Dictionary<any>
  fromTitle?: boolean
}

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
    imports: [
        BreadcrumbButtonsComponent,
        RouterLink,
        AsyncPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private store = inject<Store<any>>(Store)
  private ref = inject(ChangeDetectorRef)
  private router = inject(Router)

  public isLoggedIn$: Observable<boolean>
  public breadcrumbs: Breadcrumb[] = []
  private newsletterMode = false
  private newsletterID = 0
  public routeChangeSub$: Subscription = Subscription.EMPTY

  constructor() {
    const router = this.router;

    this.calculateBreadcrumbs(router.url)
    this.isLoggedIn$ = this.store.select(isLoggedIn)
  }

  ngOnInit(): void {
    this.routeChangeSub$ = this.router.events.subscribe((event): void => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.calculateBreadcrumbs(event.url)
        }, 100)
      }
    })

    let currentID = 0
    this.store.select(selectCurrentArticle()).subscribe((article: any) => {
      if (article && currentID !== article.id) {
        currentID = article.id

        if (this.newsletterMode) {
          const newsletterName = article.extras['subject']?.split(':')[1]?.trim()
          if (newsletterName) {
            this.breadcrumbs[3] = {
              name: newsletterName,
              active: true,
              link: '/news/newsletter/' + this.newsletterID,
            }
          }
        }
        if (this.breadcrumbs[this.breadcrumbs.length - 1].active) {
          this.breadcrumbs.push({
            name: (<Article>article).title,
            active: false,
          })
        } else {
          this.breadcrumbs[this.breadcrumbs.length - 1].name = (<Article>article).title
        }
        this.ref.markForCheck()
      }
    })
  }

  ucwords(words: string) {
    return words.replace('-', ' ').replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.slice(1)
    })
  }

  calculateBreadcrumbs(url: string) {
    this.breadcrumbs[0] =
    {
      name: 'Home',
      active: true,
      link: '/home',
    }
    this.newsletterMode = false

    let crumbCount = 1
    if (url !== '/home') {
      const parsedURL = new URL(url, window.location.origin)

      let link = ''
      for (const pathPart of parsedURL.pathname.split('/')) {

        const pathPartAsNumber = parseInt(pathPart)

        if (this.newsletterMode && crumbCount === 3) {
          this.newsletterID = pathPartAsNumber
          if (!this.breadcrumbs[crumbCount] || !this.breadcrumbs[crumbCount].active) {
            this.breadcrumbs[crumbCount] = {
              name: 'Contents',
              active: true,
              link: link + `/${pathPart}`,
              queryParams: { _cb: Math.random() }
            }
          }
          crumbCount++
          continue
        }

        if (pathPart !== '' && !pathPart.includes('public') && isNaN(pathPartAsNumber)) {
          link = link + `/${pathPart}`

          if (pathPart === 'keip-kerp') {
            this.breadcrumbs[crumbCount] = {
              name: 'KEIP/KERPs',
              active: true,
              link,
            }
          } else if (pathPart === 'secdocuments') {
            this.breadcrumbs[crumbCount] = {
              name: 'SEC Documents',
              active: false,
            }
          } else {
            if (pathPart === 'data') {
              this.breadcrumbs[crumbCount] = {
                name: this.ucwords(pathPart),
                active: true,
              }
            } else {
              if (pathPart === 'newsletter') {
                this.newsletterMode = true
                this.breadcrumbs[crumbCount] = {
                  name: 'Newsletters',
                  active: true,
                  link,
                  queryParams: { _cb: Math.random() }
                }
              } else {
                this.breadcrumbs[crumbCount] = {
                  name: this.ucwords(pathPart),
                  active: true,
                  link,
                  queryParams: { _cb: Math.random() }
                }
              }
            }
          }
          crumbCount++
        } else if (pathPartAsNumber > 0) {
          if (this.breadcrumbs[crumbCount]) {
            crumbCount++
          }
        }
      }

      if (parsedURL.searchParams.get('title') && !this.breadcrumbs.find((crumb) => crumb.fromTitle ?? false)) {
        let queryParams: Params = {}
        parsedURL.searchParams.forEach((value, key) => {
          if (<string>key === '_cb') {
            value = Math.random().toString()
          }
          queryParams[<string>key] = value
        })

        this.breadcrumbs[crumbCount] = {
          name: parsedURL.searchParams.get('title')!,
          active: true,
          link,
          queryParams,
          fromTitle: true,
        }
        crumbCount++
      }
    }
    this.breadcrumbs.length = crumbCount
    this.ref.markForCheck()
  }

  ngOnDestroy(): void {
    this.routeChangeSub$.unsubscribe()
  }
}